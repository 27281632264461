<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        okText="确定"
        @ok="onout"
        :closable="false"
        :maskClosable="false"
        :visible="logvisible"
        :cancel-button-props="{ props: { disabled: true } }"
      >
        登录已失效，请前往重新登陆
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>招商实施</a-breadcrumb-item>
            <a-breadcrumb-item>洽谈管理</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="visible == ''" class="Enter-top-right">
          <a-button
            v-show="permissionlist.indexOf('/business/talk:新增') > -1"
            style="margin: 0 15px"
            type="primary"
            @click.prevent="showDrawer"
          >
            新增洽谈
          </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="onClose"
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-model="params['state'].value"
                  v-if="i == '洽谈状态'"
                  style="width: 178px"
                >
                  <a-select-option value="0"> 洽谈中 </a-select-option>
                  <a-select-option value="1"> 中止 </a-select-option>
                  <a-select-option value="2"> 已转 </a-select-option>
                </a-select>
                <a-input
                  v-else
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-modal
        :width="500"
        title="铺位列表"
        :visible="lockvisible"
        @cancel="onCloselock"
      >
        <div style="flex-wrap: wrap" class="content-body flex-d-row">
          <div
            v-for="item in form.stores"
            :key="item.name"
            style="position: relative; margin-bottom: 40px"
          >
            <div
              style="line-height: 65px; margin-right: 10px"
              :class="
                item.locked == 0
                  ? 'locksb border-suc flex-d-column'
                  : 'locksb border-er flex-d-column'
              "
            >
              <span>{{ item.storeName }}</span>
            </div>
            <div class="deletestore" style="left: 5px; width: 20px">
              <a-icon
                @click="getlocked(item.storeId, item.locked)"
                v-if="item.locked == 1"
                style="font-size: 11px; z-index: 10; color: #ff4d4f"
                type="lock"
              />
              <a-icon
                @click="getlocked(item.storeId, item.locked)"
                v-else
                style="font-size: 11px; z-index: 10; color: #52c41a"
                type="unlock"
              />
            </div>
            <div class="locked flex-d-row">
              <div
                @click="getlocked(item.storeId, item.locked)"
                :style="{
                  'background-color': item.locked == 0 ? '#FF4D4F' : '#1890ff',
                }"
                class="btn-lock"
              >
                {{ item.locked == 0 ? '锁定' : '解锁' }}
              </div>
            </div>
          </div>
        </div>
      </a-modal>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="Talklist"
              :rowClassName="rowClassName"
            >
              <span slot="success" slot-scope="text">
                <a-progress :percent="text" size="small" />
              </span>
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="status" slot-scope="text">
                <a-badge
                  :status="text | statusTypeFilter"
                  :text="text | statusFilter"
                />
              </span>
              <template slot="action" slot-scope="text, record">
                <a
                  v-show="permissionlist.indexOf('/business/talk:修改') > -1"
                  @click="onEdit(record)"
                  >编辑</a
                >
                &nbsp;|&nbsp;
                <a @click="onhistory(record)">洽谈记录</a> &nbsp;|&nbsp;
                <a @click="onfollow(record)">跟进</a> &nbsp;|&nbsp;
                <a @click="onchangestate(record)">{{
                  record.state == 0 ? '终止' : record.state == 1 ? '恢复' : ''
                }}</a>
                &nbsp;|&nbsp;
                <a @click="onfile(record)">补充资料</a> &nbsp;|&nbsp;
                <a @click="onlock(record)">铺位</a>
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
        </div>
        <div>
          <a-modal
            v-if="form.id == ''"
            :width="1000"
            title="洽谈记录"
            :visible="visibless"
            :confirm-loading="confirmLoading"
            @cancel="onClose"
          >
            <a-table
              rowKey="id"
              :pagination="false"
              :columns="column"
              :data-source="historielist"
              :rowClassName="rowClassName"
            >
              <span slot="main" slot-scope="text, record"
                >{{ record.contacted == 1 ? '联系 ' : ''
                }}{{ record.watched == 1 ? '看房 ' : ''
                }}{{ record.priced == 1 ? '报价 ' : ''
                }}{{ record.intended == 1 ? '意向合同 ' : ''
                }}{{ record.signed == 1 ? '正式合同' : '' }}</span
              >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
            </a-table>
          </a-modal>
          <a-drawer
            v-else
            :width="400"
            title="编辑"
            placement="right"
            :closable="false"
            :visible="visibless"
            @close="onClose"
          >
            <a-upload-dragger
              v-if="Id == 1"
              :multiple="true"
              :file-list="fileList"
              action="/api/sccn/public/upload"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">点击上传</p>
              <p class="ant-upload-hint">
                支持单个或批量上传。严格禁止 上传公司数据或其他波段文件
              </p>
            </a-upload-dragger>
            <div v-else>
              <a-form-model-item style="width: 100%" label="客户">
                <a-select
                  :filter-option="filterOption"
                  @change="onclient"
                  style="width: 100%"
                  v-model="form.clientId"
                >
                  <a-select-option v-for="it in Clientlist" :key="it.id">
                    {{ it.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item style="width: 100%" label="负责人">
                <a-select
                  placeholder="请选择负责人"
                  style="width: 100%"
                  :filter-option="filterOption"
                  v-model="form.belongTo"
                >
                  <a-select-option v-for="item in userlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item style="width: 100%" label="客户联系人">
                <a-select
                  v-model="form.clientContactId"
                  :filter-option="filterOption"
                  style="width: 100%"
                >
                  <a-select-option v-for="it in contactslist" :key="it.id">
                    {{ it.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
            <div class="save-draw" v-if="Id == 1">
              <a-button type="primary" @click="savefile"> 提交 </a-button>
              <a-button @click="onClose" style="margin-left: 30px">
                取消
              </a-button>
            </div>
            <div v-else class="save-draw">
              <a-button type="primary" @click="saveput"> 保存 </a-button>
              <a-button @click="onClose" style="margin-left: 30px">
                取消
              </a-button>
            </div>
          </a-drawer>
        </div>
      </a-layout-content>
    </a-layout>
    <!-- 编辑页 -->
    <a-layout
      v-show="visible == true"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        :style="{
          background: '#fff',
          margin: 0,
        }"
      >
        <div class="content-title">
          <span>基础信息</span>
        </div>
        <a-form-model
          style="flex-wrap: wrap"
          class="content-body flex-d-row"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="clientId" class="ant--input" label="客户">
            <a-select
              :filter-option="filterOption"
              @change="onclient"
              style="width: 100%"
              v-model="form.clientId"
            >
              <a-select-option v-for="it in Clientlist" :key="it.id">
                {{ it.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            prop="clientContactId"
            class="ant--input"
            label="客户联系人"
          >
            <a-select
              v-model="form.clientContactId"
              :filter-option="filterOption"
              style="width: 100%"
            >
              <a-select-option v-for="it in contactslist" :key="it.id">
                {{ it.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="belongTo" class="ant--input" label="负责人">
            <a-select
              placeholder="请选择负责人"
              style="width: 100%"
              :filter-option="filterOption"
              v-model="form.belongTo"
              @change="belongto"
            >
              <a-select-option v-for="item in userlist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="talkBy" class="ant--input" label="洽谈人">
            <a-select
              placeholder="请选择洽谈人"
              style="width: 100%"
              :filter-option="filterOption"
              v-model="form.talkBy"
            >
              <a-select-option v-for="item in userlist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="洽谈时间">
            <a-date-picker style="width: 100%" @change="onChangetime" show-time>
              <template slot="renderExtraFooter"> </template>
            </a-date-picker>
          </a-form-model-item>
          <a-form-model-item class="ant--input" label="下次跟进时间">
            <a-date-picker
              style="width: 100%"
              @change="onChangenexttime"
              show-time
            >
              <template slot="renderExtraFooter"> </template>
            </a-date-picker>
          </a-form-model-item>
          <a-form-model-item
            prop="talkType"
            class="ant--input"
            label="洽谈类型"
          >
            <a-select
              default-value="铺位"
              v-model="form.talkType"
              @change="ontalktypeId"
              style="width: 100%"
            >
              <a-select-option value="0"> 铺位</a-select-option>
              <a-select-option value="1"> 多经点位</a-select-option>
              <a-select-option value="2"> 广告位</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            ref="talkDetail"
            prop="talkDetail"
            style="width: 50%; min-height: 140px"
            label="洽谈说明"
          >
            <a-input
              style="min-height: 140px"
              type="textarea"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
              placeholder="请输入洽谈内容"
              v-model="form.talkDetail"
            />
          </a-form-model-item>
          <a-form-model-item
            style="width: 50%"
            class="ant--input"
            label="洽谈内容"
          >
            <a-checkbox @change="oncontacted"> 联系 </a-checkbox>
            <a-checkbox @change="onwatched"> 看房 </a-checkbox>
            <a-checkbox @change="onpriced"> 报价 </a-checkbox>
            <a-checkbox @change="onintended"> 意向合同 </a-checkbox>
            <a-checkbox @change="onsigned"> 正式合同 </a-checkbox>
          </a-form-model-item>
        </a-form-model>
      </a-layout-content>
      <a-drawer
        width="600"
        title="添加铺位"
        :placement="right"
        :closable="false"
        :visible="visibles"
        @close="onclose"
      >
        <div class="search-wrop-left">
          <div class="search-left-project">
            <div class="search-left-top">项目</div>
            <div style="margin-top: 10px">
              <div class="back-search">
                {{ projectName }}
              </div>
            </div>
          </div>
          <div class="search-left-build">
            <div class="search-left-top">楼栋</div>
            <div style="margin-top: 10px">
              <div
                v-for="(t, i) in buildlist"
                :key="t.id"
                @click="buildChange(t, i)"
                :class="isbuild == i ? 'back-search' : 'search-bottom'"
              >
                {{ t.name }}
              </div>
            </div>
          </div>
          <div class="search-left-floor">
            <div class="search-left-top">楼层</div>
            <div style="margin-top: 10px">
              <div
                v-for="(t, i) in floorlist"
                :key="t.id"
                @click="floorChange(t, i)"
                :class="isfloor == i ? 'back-search' : 'search-bottom'"
              >
                {{ t.name }}
              </div>
            </div>
          </div>
          <div class="search-left-stoor">
            <div class="search-left-top">
              <input
                class="input-checkBox"
                type="checkbox"
                v-model="checkAll"
              />
              &nbsp;&nbsp;铺位
            </div>
            <div style="margin-top: 10px">
              <div
                v-for="(t, i) in storelist"
                :key="t.id"
                @click="storeChange(t, i)"
                :class="isstore == i ? 'back-search' : 'search-bottom'"
              >
                <input
                  :disabled="
                    t.state == 1
                      ? 'disabled'
                      : t.rentState == 1
                      ? 'disabled'
                      : t.locked == 1
                      ? 'disabled'
                      : false
                  "
                  type="checkbox"
                  @click="onchecked(t.id)"
                  class="input-checkBox"
                  :value="t.id"
                  v-model="checkStore"
                />&nbsp;&nbsp;
                {{ t.name }}
              </div>
            </div>
          </div>
        </div>
        <div style="flex-wrap: wrap" class="flex-d-row">
          <a-form-item class="ant--input" label="建筑面积">
            <a-input
              disabled="disabled"
              placeholder="请输入建筑面积"
              v-model="stores.totalArea"
            />
          </a-form-item>
          <a-form-item class="ant--input" label="使用面积">
            <a-input
              disabled="disabled"
              placeholder="请输入建筑面积"
              v-model="stores.useArea"
            />
          </a-form-item>
          <a-form-item class="ant--input" label="计租面积">
            <a-input
              disabled="disabled"
              placeholder="请输入建筑面积"
              v-model="stores.rentArea"
            />
          </a-form-item>
          <a-form-model-item class="ant--input" label="产权属性">
            <a-select
              disabled="disabled"
              style="width: 100%"
              :filter-option="filterOption"
              placeholder="请选择产权"
              v-model="stores.propertyTypeId"
            >
              <a-select-option v-for="item in typelist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-item style="width: 33%" label="招商类型">
            <a-select
              disabled="disabled"
              v-model="stores.state"
              style="width: 100%"
            >
              <a-select-option value="0"> 启用</a-select-option>
              <a-select-option value="1"> 禁用</a-select-option>
            </a-select>
          </a-form-item>

          <div class="flex-d-row" style="width: 65%" v-if="stores.state == 0">
            <a-form-item style="width: 50%" label="有意人数">
              <a-input
                disabled="disabled"
                placeholder="请输入有意人数"
                v-model="stores.talkDetail"
              />
            </a-form-item>
            <a-form-item style="width: 50%" class="ant--input" label="租赁状态">
              <a-select
                disabled="disabled"
                v-model="stores.locked"
                style="width: 100%"
              >
                <a-select-option value="0"> 未租赁</a-select-option>
                <a-select-option value="1"> 已租赁</a-select-option>
              </a-select>
            </a-form-item>
            <!-- <a-form-item
              style="width:30%"
              v-if="stores.locked == 1"
              class="ant--input"
              label="到期时间"
            >
              <a-input
                disabled="disabled"
                placeholder="到期时间"
                v-model="stores.updateTime"
              />
            </a-form-item> -->
          </div>
        </div>
        <div class="save-draw">
          <a-button
            v-if="
              stores.state == 1 || stores.rentState == 1 || stores.locked == 1
            "
            disabled="disabled"
            type="primary"
          >
            保存
          </a-button>
          <a-button v-else type="primary" @click="handleOk"> 保存 </a-button>
          <a-button @click="onclose" style="margin-left: 30px"> 取消 </a-button>
        </div>
      </a-drawer>
      <!-- 价格 -->
      <a-layout-content style="background: #fff; margin: 24px 0">
        <div class="content-title">
          <span>租赁列表</span>
        </div>
        <div
          style="overflow: auto; overflow-y: hidden"
          class="content-body flex-d-row"
        >
          <div
            v-for="(item, index) in form.stores"
            :key="item.name"
            style="position: relative"
          >
            <div
              style="line-height: 65px; margin-right: 10px"
              class="body-building flex-d-column"
              @click="Addmobile(item, index)"
            >
              <span>{{ item.storeName }}</span>
            </div>
            <span @click="deletestore(index)" class="deletestore">x</span>
            <div class="deletestore" style="left: 5px; width: 20px">
              <a-icon
                v-if="item.locked == 1"
                style="font-size: 11px; z-index: 10"
                type="lock"
              />
              <a-icon
                v-else
                style="font-size: 11px; z-index: 10"
                type="unlock"
              />
            </div>
          </div>
          <div @click="Addmobile(undefined)" class="body-building">+</div>
        </div>
      </a-layout-content>
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw>
        <a-button html-type="submit" type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
const column = [
  {
    title: '洽谈内容',
    dataIndex: 'id',
    scopedSlots: { customRender: 'main' },
  },
  {
    title: '洽谈说明',
    dataIndex: 'talkDetail',
  },
  {
    title: '洽谈时间',
    dataIndex: 'talkTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '联系人',
    dataIndex: 'clientContactName',
  },
  {
    title: '洽谈人',
    dataIndex: 'talkByName',
  },
  {
    title: '下次跟进时间',
    dataIndex: 'nextTime',
    scopedSlots: { customRender: 'time' },
  },
]
const columns = [
  {
    title: '客户名称',
    dataIndex: 'reason',
  },
  {
    title: '项目名称',
    dataIndex: 'projectName',
  },
  {
    title: '负责人',
    dataIndex: 'belongToName',
  },
  {
    title: '客户联系人',
    dataIndex: 'clientContactName',
  },
  {
    title: '下次跟进时间',
    dataIndex: 'nextTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '洽谈完成率',
    dataIndex: 'successRate',
    scopedSlots: { customRender: 'success' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const statusMap = {
  0: {
    status: 'processing',
    text: '洽谈中',
  },
  1: {
    status: 'error',
    text: '终止',
  },
  2: {
    status: 'success',
    text: '已转',
  },
}
// import { api } from "@/http/api";
import http from '../../../http'
export default {
  data() {
    return {
      permissionlist: [],
      fileList: [],
      isproject: 0,
      isbuild: 0,
      textlist: ['客户名称', '意向铺位', '洽谈时间', '洽谈状态'],
      isfloor: 0,
      isstore: 0,
      checked: false,
      checkStore: [],
      checkStores: [],
      historielist: '',
      projectlist: [],
      buildlist: [],
      floorlist: [],
      storelist: [],
      Clientlist: [],
      contactslist: [],
      userlist: [],
      typelist: [],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      contactindex: '',
      column,
      columns,
      lockvisible: false,
      visibles: false,
      title: '',
      logvisible: false,
      visible: false,
      visibless: false,
      placement: 'right',
      labelCol: { span: 15 },
      wrapperCol: { span: 24 },
      projectName: '',
      params: {
        current: 1,
        pageSize: 6,
        reason: {
          value: '',
          op: '%like%',
        },
        state: {
          value: '',
          op: '=',
        },
      },
      total: 0,
      store: {
        storeId: '0',
        storeName: '',
      },
      stores: [],
      Talklist: [],
      selectedRowKey: [],
      Id: '',
      form: {
        contacted: '0',
        watched: '0',
        priced: '0',
        intended: '0',
        signed: '0',
        id: '',
        state: '',
        belongTo: '',
        clientId: '',
        clientContactId: '',
        projectId: '',
        talkBy: '',
        talkDetail: '',
        talkTime: '',
        talkType: '0',
        nextTime: '',
        stores: [],
      },
      rules: {
        projectId: [
          {
            required: true,
            message: '项目不能为空',
            trigger: 'change',
          },
        ],
        clientId: [
          {
            required: true,
            message: '客户不能为空',
            trigger: 'change',
          },
        ],
        clientContactId: [
          {
            required: true,
            message: '客户联系人不能为空',
            trigger: 'change',
          },
        ],
        belongTo: [
          {
            required: true,
            message: '负责人不能为空',
            trigger: 'change',
          },
        ],
        talkBy: [
          {
            required: true,
            message: '洽谈人不能为空',
            trigger: 'change',
          },
        ],
        talkType: [
          {
            required: true,
            message: '洽谈类型不能为空',
            trigger: 'change',
          },
        ],
        talkDetail: [
          {
            required: true,
            message: '洽谈内容不能为空',
            trigger: 'change',
          },
        ],
      },
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    },
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    handleSearch(e) {
      //   console.log(this.params);
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        // console.log(values);
        this.params['reason'].value = values.客户名称
      })
      this.getTalk()
    },
    handleReset() {
      this.fom.resetFields()
    },
    handleChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      let fileList = [...info.fileList]

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-2)

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.data.file.url
        }
        return file
      })

      this.fileList = fileList
    },
    onfile(e) {
      this.Id = 1
      this.form.id = e.id
      this.fileList = JSON.parse(e.files)
      this.visibless = true
    },
    oncontacted(e) {
      if (e.target.checked == true) {
        this.form.contacted = 1
      } else {
        this.form.contacted = 0
      }
    },
    onwatched(e) {
      if (e.target.checked == true) {
        this.form.watched = 1
      } else {
        this.form.watched = 0
      }
    },
    onpriced(e) {
      if (e.target.checked == true) {
        this.form.priced = 1
      } else {
        this.form.priced = 0
      }
    },
    onintended(e) {
      //   console.log(e);
      if (e.target.checked == true) {
        this.form.intended = 1
      } else {
        this.form.intended = 0
      }
    },
    onsigned(e) {
      if (e.target.checked == true) {
        this.form.signed = 1
      } else {
        this.form.signed = 0
      }
    },
    onChangecheckbox(e) {
      console.log(e)
    },
    handleOk() {
      if (this.contactindex == undefined) {
        this.checkStores.forEach(it => {
          this.form.stores.push(it)
        })
        // console.log(this.checkStores);
      } else {
        // console.log(this.contactindex);
        this.form.stores.splice(this.contactindex, 1, this.checkStores[0])
      }
      //   console.log(this.form.stores);
      this.onclose()
    },
    onclose() {
      this.visibles = false
      this.stores = []
      this.checkStore = []
      this.checkStores = []
      this.store = {
        storeId: '',
        storeName: '',
      }
    },
    Addmobile(e, index) {
      if (e == undefined) {
        this.contactindex = undefined
      } else {
        this.contactindex = index
        // console.log(e);
        this.getStoreinfo(e.storeId)
      }
      this.storetype(this.floors || this.floorlist[0].id, 0)
      this.visibles = true
    },
    deletestore(e) {
      this.form.stores.splice(e, 1)
      this.onclose()
    },
    getlocked(id, locked) {
      let lock = 0
      if (locked == 0) {
        lock = 1
      } else {
        lock = 0
      }
      this.getpropertylock(id, lock)
    },
    onCloselock() {
      this.lockvisible = false
      this.form = {
        contacted: '0',
        watched: '0',
        priced: '0',
        intended: '0',
        signed: '0',
        id: '',
        state: '',
        belongTo: '',
        clientId: '',
        clientContactId: '',
        projectId: '',
        talkBy: '',
        talkDetail: '',
        talkTime: '',
        talkType: '0',
        nextTime: '',
        stores: [],
      }
    },
    onhistory(item) {
      this.visibless = true
      this.title = '洽谈记录'
      this.getTalkhistory(item.id)
    },
    onfollow(item) {
      this.Id = item.id
      this.form.id = item.id
      this.visible = true
      this.getTalkId(item.id)
      //   this.putfollow(item.id);
    },
    onChangetime(e) {
      //   console.log(e._d);
      this.form.talkTime = e._d
    },
    onChangenexttime(e) {
      this.form.nextTime = e._d
    },
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.StateTalk(e.id, this.form.state)
    },
    onClose() {
      this.visibless = false
      this.visible = false
      this.Id = ''
      this.form = {
        contacted: '0',
        watched: '0',
        priced: '0',
        intended: '0',
        signed: '0',
        belongTo: '',
        clientId: '',
        state: '',
        id: '',
        clientContactId: '',
        projectId: '',
        talkBy: '',
        talkDetail: '',
        talkTime: '',
        talkType: '0',
        nextTime: '',
        stores: [],
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          if (this.Id == '') {
            this.AddTalk(this.form)
          } else if (this.Id != '') {
            this.putfollow(this.form)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    savefile() {
      this.Talkfile(this.form.id, JSON.stringify(this.fileList))
      //   console.log(this.fileList);
    },
    saveput() {
      this.putTalk(this.form)
      this.visibless = false
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.visibless = true
      this.title = '编辑'
      const users = JSON.parse(localStorage.getItem('store-userlist'))
      this.form.projectId = users.projectInfo.projectId
      this.projectName = users.projectInfo.projectName
      this.getClientlist()
      this.getTalkId(item.id)
    },
    onlock(item) {
      this.form.id = item.id
      this.lockvisible = true
      this.getTalkId(item.id)
    },
    async getpropertylock(id, locked) {
      try {
        const res = await http.getpropertylock(id, locked)
        const { success, msg } = res.data
        if (success) {
          this.getTalkId(this.form.id)
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    async putTalk(form) {
      try {
        const res = await http.putTalk(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    //上传文件
    async Talkfile(id, file) {
      try {
        const res = await http.Talkfile(id, file)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        this.$message.error(ex)
      }
    },
    async putfollow(form) {
      try {
        const res = await http.putfollow(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.onClose()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    onpagesize(e) {
      this.params.current = e
      this.getTalk()
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
      this.$store.commit('selectKeys')
      const users = JSON.parse(localStorage.getItem('store-userlist'))
      this.form.projectId = users.projectInfo.projectId
      this.projectName = users.projectInfo.projectName
      this.buildingid(users.projectInfo.projectId)
    },
    async getTalk() {
      try {
        const res = await http.getTalk(this.params)
        const { success, data } = res.data
        if (success) {
          this.Talklist = data.talks.records
          this.total = data.talks.total
          //   console.log(data.talks);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getTalkId(id) {
      try {
        const res = await http.getTalkId(id)
        const { success, data } = res.data
        if (success) {
          this.form.belongTo = data.talk.belongTo
          this.form.clientId = data.talk.clientId
          this.form.clientContactId = data.talk.clientContactId
          this.form.projectId = data.talk.projectId
          this.form.talkBy = data.talk.belongTo
          this.form.talkType = data.talk.talkType + ''
          this.form.stores = data.talk.stores
          this.getClientcontact(data.talk.clientId)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async StateTalk(id, state) {
      try {
        const res = await http.StateTalk(id, state)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    // 新增
    async AddTalk(form) {
      try {
        const res = await http.AddTalk(form)
        const { success, msg } = res.data
        if (success) {
          this.timer()
          this.$message.success('操作成功')
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:', ex)
        this.$message.error(ex)
      }
    },
    async getTalkhistory(talkid) {
      try {
        const res = await http.getTalkhistory(talkid)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.historielist = data.histories
          this.Id = data.histories
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    //Change事件
    properyChange(t, i) {
      this.isproject = i
      this.buildingid(t.id)
      this.floorlist = []
      this.storelist = []
      this.stores = []
    },
    buildChange(t, i) {
      this.isbuild = i
      this.floorid(t.id)
      this.storelist = []
      this.stores = []
    },
    floorChange(t, i) {
      this.isfloor = i
      this.storetype(t.id, this.form.talkType)
      this.stores = []
    },
    storeChange(t, i) {
      //   console.log(t.id);
      this.getStoreinfo(t.id)
      this.isstore = i
    },
    onclient(value) {
      this.getClientcontact(value)
    },
    belongto(e) {
      console.log(e)
    },
    async project() {
      try {
        const res = await http.project()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.projectlist = data.projects
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildlist = data.buildings
          if (data.buildings.length == 0) {
            this.floorlist = []
          } else {
            this.floorid(this.buildlist[0].id)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
          this.storetype(data.floors[0].id, 0)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async storetype(floorId, type) {
      try {
        const res = await http.storetype(floorId, type)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.storelist = data.stores
          for (let a = 0; a < this.form.stores.length; a++) {
            this.storelist.forEach((i, index) => {
              if (i.name == this.form.stores[a].storeName) {
                this.storelist[index].rentState = 1
              }
            })
          }
          if (data.stores.length > 0) {
            let st = false
            data.stores.forEach((item, index) => {
              if (item.state == 0 && item.rentState == 0) {
                if (st == false) {
                  this.isstore = index
                  st = true
                }
              }
            })
            this.getStoreinfo(data.stores[this.isstore].id)
          } else {
            this.getStoreinfo(data.stores[0].id)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getuserlist() {
      try {
        const res = await http.getuserlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.userlist = data.users
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getClientlist() {
      try {
        const res = await http.getClientlist()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.Clientlist = data.clients
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getClientcontact(id) {
      try {
        const res = await http.getClientcontact(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.contactslist = data.contacts
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    onchecked(id) {
      //   console.log(id);
      let index = this.checkStore.findIndex(item => item == id)
      if (index == -1) {
        this.checkstorelist(id)
      } else {
        this.checkStores.splice(index, 1)
      }
    },
    async checkstorelist(id) {
      try {
        const res = await http.getStoreinfo(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.checkStores.push({
            storeId: data.store.id,
            storeName: data.store.name,
          })
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async getStoreinfo(id) {
      try {
        const res = await http.getStoreinfo(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.store.storeId = data.store.id
          this.store.storeName = data.store.name
          this.stores = data.store
          this.stores.state = data.store.state + ''
          this.stores.locked = data.store.locked + ''
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async type() {
      try {
        const res = await http.type()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.typelist = data.propertyTypes
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    ontalktypeId(e) {
      if (e != 0) {
        this.form.stores = []
        this.storetype('1426030017037049857', this.form.talkType)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getTalk()
      }, 500)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    onout() {
      this.$router.push({ name: 'login' })
    },
  },
  mounted() {
    this.getTalk()
    const users = JSON.parse(localStorage.getItem('store-userlist'))
    if (users == null) {
      this.logvisible = true
    }
    this.permissionlist = JSON.parse(
      localStorage.getItem('store-userlist')
    ).permissions
    this.form.projectId = users.projectInfo.projectId
    this.projectName = users.projectInfo.projectName
    this.buildingid(users.projectInfo.projectId)
    this.getClientlist()
    this.getuserlist()
    this.type()
    this.form.belongTo = users.id + ''
    this.form.talkBy = users.id + ''
    if (this.$route.query.visible == 'true') {
      this.showDrawer()
    }
  },
  computed: {
    count() {
      return this.expand ? 11 : 7
    },
    checkAll: {
      get() {
        return this.checkStore.length === this.storelist.length
      },
      set(val) {
        if (val) {
          this.checkStore = []
          this.checkStores = []
          this.storelist.forEach(storelist => {
            if (storelist.state == 1 || storelist.rentState == 1) {
              console.log()
            } else {
              this.checkStore.push(storelist.id)
              this.checkstorelist(storelist.id)
            }
          })
        } else {
          this.checkStore = []
          this.checkStores = []
        }
      },
    },
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.company {
  height: 100%;
  width: 100%;
}
.price {
  position: absolute;
  right: 1px;
  bottom: -6px;
  width: 70px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
}
.search-wrap {
  width: 100%;
  /* background: #999;*/
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 630px;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.ant-btn {
  margin: 0px 8px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-layout {
  height: 100%;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.code-box-demo .ant-slider {
  margin-bottom: 16px;
}
.locked {
  width: calc(100% - 10px);
  position: absolute;
  bottom: -25px;
}
.btn-lock {
  width: 43px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  border-radius: 5px;
  margin: auto;
  font-size: 13px;
  cursor: pointer;
  color: #fff;
  background-color: #1890ff;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.search-wrop-left {
  height: 450px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  .search-left-project,
  .search-left-build,
  .search-left-floor {
    border-right: 1px solid #d9d9d9;
    min-width: 130px;
    width: 25%;
    overflow-y: auto;
    height: 100%;
  }
  .search-bottom {
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #fff;
  }
  .search-bottom:hover {
    background-color: #f5f5f5;
  }
  .search-left-top {
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #f0f1f4;
  }
  .search-left-stoor {
    min-width: 130px;
    width: 25%;
    height: 100%;
    overflow-y: auto;
  }
}
.price-formitem {
  position: relative;
  width: 50%;
}
.back-search {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #f5f5f5;
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.locksb {
  min-width: 65px;
  line-height: 65px;
  min-height: 65px;
  text-align: center;
}
.border-suc {
  color: #52c41a;
  border: 1px dashed #52c41a;
}
.border-er {
  color: #ff4d4f;
  border: 1px dashed #ff4d4f;
}
.content-body {
  padding: 24px;
  .body-building {
    min-width: 65px;
    line-height: 65px;
    min-height: 65px;
    text-align: center;
    border: 1px dashed #999;
  }
  .body-building:hover {
    cursor: pointer;
    color: rgb(197, 172, 29);
    border: 1px dashed rgb(197, 172, 29);
  }
}
.save-draw {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-top: 1px solid #eaeaea;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.deletestore {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: -3px;
  z-index: 10;
}
.deletestore:hover {
  cursor: pointer;
  color: rgb(197, 172, 29);
}
.ant-form-item {
  height: 90px;
  margin: 0;
  padding-right: 20px;
}
.ant--input {
  width: 25%;
}
.input-checkBox {
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
